import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import '../styles/styles-project-detail.css'
import Helmet from "react-helmet"
import Testemunho from './testemunho';






const Project = ({ primeirasimagens, segundasimagens, terceirasimagens, quartasimagens, title, briefing, approach, testemunhotexto, testemunhoimagem, client, segundaimagem, terceiraimagem, quartaimagem, nomecliente, clientepos }) => (
    
    <div>
      
<div id="container-total">
    <div id="container-capa">
        <div id="wrapper-conteudo">
            <div  data-sal="slide-up" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease" id="conteudo-100-col">
            {/* <Image fluid={primeiraimagem} alt={title} /> */}
            <Image fluid={primeirasimagens} alt={title} loading="lazy" />
            </div>
        </div>
    </div>
    <div id="container-briefing">
        <div id="wrapper-conteudo">
            <div id="conteudo-100-col">
            <p  data-sal="slide-up" data-sal-delay="0" data-sal-duration="800" data-sal-easing="ease" className="titulo-texto gt-light">Briefing</p>
            <p  data-sal="slide-up" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease" className="texto gt-light">{briefing}</p>
            </div>
        </div>
    </div>

    <div id="container-post1">
        <div id="wrapper-conteudo">
            <div id="conteudo-100-col">
            <Image fluid={segundasimagens} alt={title}  />

            </div>
        </div>
    </div>

    <div id="container-approach">
        <div id="wrapper-conteudo">
            <div id="conteudo-100-col">
            <p  data-sal="slide-up" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease" className="titulo-texto gt-light">Our Approach</p>
            <p  data-sal="slide-up" data-sal-delay="600" data-sal-duration="800" data-sal-easing="ease" className="texto gt-light">{approach}</p>
            </div>
        </div>
    </div>

    <div id="container-imagem3">
        <div id="wrapper-conteudo-3">
            <div id="conteudo-100-col" className="col">
           <div id="post2-1">
           <Image fluid={terceirasimagens} alt={title} />
           </div>
            </div>
    
        </div>
    </div>
    <div id="container-imagem4">
        <div id="wrapper-conteudo-4">
            <div id="conteudo-100-col" className="col">
           <div  data-sal="slide-up" data-sal-delay="0" data-sal-duration="800" data-sal-easing="ease" id="post2-1">
           <Image  fluid={quartasimagens} alt={title} />
           </div>
            </div>
    
        </div>
    </div>

  
</div>


     

    </div>

)

export default Project;