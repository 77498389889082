import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import '../styles/styles-project-detail.css'
import Helmet from "react-helmet"






const Testemunho = ({ testemunhotexto, testemunhoimagem, client, nomecliente, clientepos,title }) => (
    
    <div>
            <div id="container-feedback">
        <div id="wrapper-conteudo">
            <div id="conteudo-100-col" className="center">
            <Image  fluid={testemunhoimagem} className="client-img" alt={title} />

                <p className="gt-regular nome-cliente">{nomecliente}</p><p className="gt-light titulo-cliente grey">{clientepos} @ {client}</p>
                <p className="gt-light feedback-texto">"{testemunhotexto}"</p>
            </div>
        </div>
    </div>
    </div>
  

    )
    
    export default Testemunho;