import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from 'gatsby'
import Project from "../components/project"
import Testemunho from "../components/testemunho"
import Helmet from "react-helmet"



export const query = graphql`
    query($slug: String!){
          projectsJson(slug: {eq: $slug }) {
            title
            ano
            client
            briefing
            approach
            testemunhotexto
            nomecliente
            clientepos
            testemunhoimagem{
                childImageSharp{
                    fluid(quality: 100)  {
                        ...GatsbyImageSharpFluid_withWebp

                      } 
                }
            }
            imagecover{
              childImageSharp{
                fluid{
                  ...GatsbyImageSharpFluid
                }
              }
            }

          }
        desktop: projectsJson(slug: {eq: $slug }) {
            primeiraimagem{
                childImageSharp{
                    fluid(maxWidth: 1400) {
                        ...GatsbyImageSharpFluid

                    } 
                }
            }
            segundaimagem{
              childImageSharp{
                fluid(maxWidth: 1400) {
                      ...GatsbyImageSharpFluid_withWebp

                      } 
              }
            }
            terceiraimagem{
              childImageSharp{
                fluid(maxWidth: 1400)  {
                      ...GatsbyImageSharpFluid_withWebp

                } 
              }
            }
            quartaimagem{
              childImageSharp{
                fluid(maxWidth: 1400)  {
                      ...GatsbyImageSharpFluid_withWebp

                } 
              }
            }
          }
        
        mobile: projectsJson(slug: {eq: $slug }) {
            primeiraimagemmobile{
                childImageSharp{
                    fluid(maxWidth: 700)  {
                        ...GatsbyImageSharpFluid

                    } 
                }
            }
            segundaimagemmobile{
              childImageSharp{
                  fluid(maxWidth: 700)  {
                      ...GatsbyImageSharpFluid

                  } 
              }
            }
            terceiraimagemmobile{
              childImageSharp{
                  fluid(maxWidth: 700)  {
                      ...GatsbyImageSharpFluid

                  } 
              }
            }
            quartaimagemmobile{
              childImageSharp{
                  fluid(maxWidth: 700)  {
                      ...GatsbyImageSharpFluid

                  } 
              }
            }
        }
         
        
 
    }

`;




const ProjectTemplate =({ data }) =>{
   
    const project = data.projectsJson;
    const { desktop, mobile } = data;
    const primeirasimagens = [
        {
          ...mobile.primeiraimagemmobile.childImageSharp.fluid,
          media: "(max-width: 700px)",
          
        },
        {
          ...desktop.primeiraimagem.childImageSharp.fluid,
          media: "(min-width: 1001px)",
        },
      ];
      const segundasimagens = [
        {
          ...mobile.segundaimagemmobile.childImageSharp.fluid,
          media: "(max-width: 700px)",
          
        },
        {
          ...desktop.segundaimagem.childImageSharp.fluid,
          media: "(min-width: 1001px)",
        },
      ];
      const terceirasimagens = [
        {
          ...mobile.terceiraimagemmobile.childImageSharp.fluid,
          media: "(max-width: 700px)",
          
        },
        {
          ...desktop.terceiraimagem.childImageSharp.fluid,
          media: "(min-width: 1001px)",
        },
      ];
      const quartasimagens = [
        {
          ...mobile.quartaimagemmobile.childImageSharp.fluid,
          media: "(max-width: 700px)",
          
        },
        {
          ...desktop.quartaimagem.childImageSharp.fluid,
          media: "(min-width: 1001px)",
        },
      ];
 
    const title = project.title;
    const client = project.client;
    const briefing = project.briefing;
    const approach = project.approach;
    const nomecliente= project.nomecliente;
    const clientepos= project.clientepos;
    const testemunhotexto = project.testemunhotexto;
    const testemunhoimagem = project.testemunhoimagem.childImageSharp.fluid;

    

    
   

    

    
   






    return(
    <Layout>
       <Project 
       title={title}
       client={client}
       briefing={briefing}
       approach={approach}
       testemunhoimagem={testemunhoimagem}
       testemunhotexto={testemunhotexto}
       nomecliente={nomecliente}
       clientepos={clientepos}
       primeirasimagens={primeirasimagens}
       segundasimagens={segundasimagens}
       terceirasimagens={terceirasimagens}
       quartasimagens={quartasimagens}

        />
      {title==='Right Connection' &&<Testemunho
      testemunhoimagem={testemunhoimagem}
      client={client}
      testemunhotexto={testemunhotexto}
      nomecliente={nomecliente}
      clientepos={clientepos}
      title={title}
      />}
         {title==='FarmIN - Livestock Trainings' &&<Testemunho
      testemunhoimagem={testemunhoimagem}
      client={client}
      testemunhotexto={testemunhotexto}
      nomecliente={nomecliente}
      clientepos={clientepos}
      title={title}
      />}
           {title==='Feira de Março 2020' &&<Testemunho
      testemunhoimagem={testemunhoimagem}
      client={client}
      testemunhotexto={testemunhotexto}
      nomecliente={nomecliente}
      clientepos={clientepos}
      title={title}
      />}

    </Layout>

    )
}
export default ProjectTemplate;